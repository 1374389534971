const defaultStyle = {
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  // width: "250px",
  // minHeight: "250px",

  "@media only screen and (max-width: 395px)": {
    mwidth: "295px",
    // minHeight: "335px",
  },
};

const defaultMobileStyle = {
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  // width: "295px",
  // height: "290px",
};

export const resolveDetailMobileStyle = (rewardType) => {
  switch (rewardType) {
    case "grand":
    case "top":
      return {
        background:
          "linear-gradient(122.72deg, rgba(140, 232, 255, 0.3) -3.02%, rgba(177, 247, 192, 0.3) 101.03%)",
        ...defaultMobileStyle,
      };
    default:
      return {
        background: "rgba(255, 255, 255, 0.7)",
        ...defaultMobileStyle,
      };
  }
};

export const resolveDetailStyle = (rewardType) => {
  switch (rewardType) {
    case "grand":
    case "top":
      return {
        background:
          "linear-gradient(122.72deg, rgba(140, 232, 255, 0.3) -3.02%, rgba(177, 247, 192, 0.3) 101.03%)",
        ...defaultStyle,
      };
    default:
      return {
        background: "rgba(255, 255, 255, 0.7)",
        ...defaultStyle,
      };
  }
};

export default resolveDetailStyle;

import { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import MKTypography from "components/MKTypography";

//import colors from "assets/theme/base/colors";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { MainContext } from "contexts/MainContext";

import TrackedExtLink from "dttd/components/TrackedExtLink";
import { log_event_analytics } from "dttd/functions/log_event_analytics";

let GiveawayDetailPropType = PropTypes.shape({
  logo: PropTypes.node.isRequired,
  logoAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tracking: PropTypes.string.isRequired,
  claimUrl: PropTypes.string.isRequired,
});

function GiveawayDetail({ idx, giveaway }) {
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);

  const clickToClaim = () => {
    log_event_analytics(mainContext, "voucher_claim", {
      persona_id: mainContext.persona_id,
      location: mainContext.pathname,
      tracking: giveaway.tracking,
      claimUrl: giveaway.claimUrl,
    });
  };

  const descriptionStyle = {
    mt: 1,
    display: "block",
    lineHeight: "16px",
    color: "#737C78",
  };

  return (
    <>
      <Fragment>
        <Card key={`voucher-detail-${idx}`} sx={{ borderRadius: "8px", m: "3px" }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={0.2}
            sx={{ minHeight: "84px" }}
          >
            <Grid item xs={2} container justifyContent="center">
              <img src={giveaway.logo} alt={t(giveaway.logoAlt)} width="69px" />
            </Grid>
            <Grid item xs={6} justifyContent="center">
              <MKTypography variant="caption" fontWeight="bold" sx={descriptionStyle}>
                <Trans i18nKey={giveaway.title} components={{ br: <br /> }} />
              </MKTypography>
            </Grid>
            <Grid item xs={3}>
              <MKTypography
                variant="button"
                component={TrackedExtLink}
                to={giveaway.claimUrl}
                href={giveaway.claimUrl}
                onClick={clickToClaim}
                sx={{
                  px: "10px",
                  py: "2px",
                  background: "linear-gradient(126.21deg, #03CAFC 6.23%, #70FC8F 100%)",
                  borderRadius: "400px",
                }}
              >
                <MKTypography variant="button" fontWeight="bold">
                  <span>{t("giveaway.button")}</span>
                </MKTypography>
              </MKTypography>
            </Grid>
          </Grid>
        </Card>
      </Fragment>
    </>
  );
}

GiveawayDetail.propTypes = {
  idx: PropTypes.number,
  giveaway: GiveawayDetailPropType,
};

export default GiveawayDetail;

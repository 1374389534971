// Images
import regalHotelLogo from "assets/images/giveaways/regal_hotel.jpg";
import regalRiversideLogo from "assets/images/giveaways/regal_riverside_logo.png";
import regalHongkongLogo from "assets/images/giveaways/regal_hongkong_logo.png";
import chartCoffeeLogo from "assets/images/giveaways/chart-coffee.png";
import cookieismLogo from "assets/images/giveaways/cookieism.png";
import checkCheckCinLogo from "assets/images/giveaways/check-check-cin-logo.png";
import foodpandaLogo from "assets/images/giveaways/foodpanda-mall-logo.png";
import pandamartLogo from "assets/images/giveaways/pandamart-logo.png";
import mezzainineMakersLogo from "assets/images/giveaways/mezzanine-makers-logo.png";

export const GiveawayList = [
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_patisserie.alt",
    title: "giveaway.regal_patisserie.title",
    tracking: "Regal Patisserie 20% off",
    //claimUrl: "https://hop.dttd.app/IMkGoYrACAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=3980d4ea-2041-4151-bee3-a6ccfe775592",
  },
  {
    logo: regalRiversideLogo,
    logoAlt: "giveaway.regal_riverside_court.alt",
    title: "giveaway.regal_riverside_court.title",
    tracking: "Regal Riverside Regal Court 20% off",
    //claimUrl: "https://hop.dttd.app/cY4acopUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=7455c39f-6600-4e2e-bb87-098ea2f47551",
  },
  {
    logo: regalRiversideLogo,
    logoAlt: "giveaway.regal_riverside_terrace.alt",
    title: "giveaway.regal_riverside_terrace.title",
    tracking: "Regal Riverside Regal Terrace 20% off",
    // claimUrl: "https://hop.dttd.app/NTA2y9zACAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=f6ca7deb-95b1-446f-a568-a7b1253ad92e",
  },
  {
    //Regal Hongkong Hotel  Regal Palace 20% off E-coupon
    logo: regalHongkongLogo,
    logoAlt: "giveaway.regal_hongkong_palace.alt",
    title: "giveaway.regal_hongkong_palace.title",
    tracking: "Regal Hongkong Regal Palace 20% off",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=e1ed89af-cf2b-454c-9e22-a0a74f677780",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_airport_hotel.alt1",
    title: "giveaway.regal_airport_hotel.title1",
    tracking: "Regal Airport Hotel Café Aficionado Dinner Buffet Buy 1 Get 1 E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=d20234d7-ae21-4996-8959-1d2e44d7f812",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_airport_hotel.alt2",
    title: "giveaway.regal_airport_hotel.title2",
    tracking: "Regal Airport Hotel Café Aficionado Dinner Buffet Buy 2 Get 2 E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=b87d8569-8d48-48ef-a315-82b356acab52",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_airport_hotel.alt3",
    title: "giveaway.regal_airport_hotel.title3",
    tracking: "Regal Airport Hotel Café Aficionado Lunch Buffet Buy 1 Get 1 E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=3dad23c8-8c5d-4a35-b556-e1603b9baeb9",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_airport_hotel.alt4",
    title: "giveaway.regal_airport_hotel.title4",
    tracking: "Regal Airport Hotel Café Aficionado Lunch Buffet Buy 2 Get 2 E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=30506542-aab2-4f89-be45-70999caaa5cb",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_kowloon_hotel.alt1",
    title: "giveaway.regal_kowloon_hotel.title1",
    tracking: "Regal Kowloon Hotel Café Allegro Dinner Buffet Buy 1 Get 1 E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=779b0b01-7e92-4436-a15f-22347119cb3c",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_kowloon_hotel.alt2",
    title: "giveaway.regal_kowloon_hotel.title2",
    tracking: "Regal Kowloon Hotel Café Allegro Dinner Buffet Buy 2 Get 2 E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=83503bca-e7b1-4620-9aaf-ce690af5fa78",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_kowloon_hotel.alt3",
    title: "giveaway.regal_kowloon_hotel.title3",
    tracking: "Regal Kowloon Hotel Café Allegro Lunch Buffet Buy 1 Get 1 E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=23a79026-7630-4818-b81e-f1e8902f1b1d",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_kowloon_hotel.alt4",
    title: "giveaway.regal_kowloon_hotel.title4",
    tracking: "Regal Kowloon Hotel Café Allegro Lunch Buffet Buy 2 Get 2 E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=22783c62-dd05-452e-9bcf-7370dcc12c5d",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_kowloon_hotel.alt5",
    title: "giveaway.regal_kowloon_hotel.title5",
    tracking: "Regal Kowloon Hotel Mezzo 20% off E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=cf1f3252-591d-4030-ba7c-f7826499f087",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_kowloon_hotel.alt6",
    title: "giveaway.regal_kowloon_hotel.title6",
    tracking: "Regal Kowloon Hotel Regal Court 20% off E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=0fefeb87-311f-4eef-bf1e-86e606bc9e44",
  },
  {
    logo: regalHotelLogo,
    logoAlt: "giveaway.regal_skycity_hotel.alt1",
    title: "giveaway.regal_skycity_hotel.title1",
    tracking: "Regal Skycity Hotel the Jade 20% off E-coupon",
    // claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=a8b67181-76f5-4780-a964-c6016d4d6c76",
  },
  {
    //Chart Coffee - Buy 1 Get 1 Coffee
    logo: chartCoffeeLogo,
    logoAlt: "giveaway.chart_coffee.alt",
    title: "giveaway.chart_coffee.title",
    tracking: "Chart Coffee - Buy 1 Get 1 Coffee",
    // claimUrl: "https://hop.dttd.app/1WNvFpGSDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=dccba76f-1f7c-4a72-8fe6-8ff98d9f4716",
  },
  {
    logo: cookieismLogo,
    logoAlt: "giveaway.cookieism.alt",
    title: "giveaway.cookieism.title",
    tracking: "Cookieism - Buy 1 Get 1 Free",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=017dcae5-f285-4186-b25e-05afaa5265ba",
  },
  {
    logo: checkCheckCinLogo,
    logoAlt: "giveaway.check_check_cin.alt1",
    title: "giveaway.check_check_cin.title1",
    tracking: "Check Check Cin - Dusk Paper Pack Rice Water x 1 case, 12 packs per case",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=a7c8bd16-0e4d-4573-9081-79320d6891a4",
  },
  {
    logo: checkCheckCinLogo,
    logoAlt: "giveaway.check_check_cin.alt2",
    title: "giveaway.check_check_cin.title2",
    tracking: "Check Check Cin - Rice Water (Random Flavour) x 1 Sachet",
    // claimUrl: "https://hop.dttd.app/dbxVPBHSDAb",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=fac270f9-df0d-45fc-9441-fbaa98846afa",
  },
  {
    logo: foodpandaLogo,
    logoAlt: "giveaway.foodpanda_mall.alt",
    title: "giveaway.foodpanda_mall.title",
    tracking: "Foodpanda Mall - Enjoy $50 off your first $100+ order on foodpanda mall",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=497b7efe-6d27-432c-8e3c-c055b139d01b",
  },
  {
    logo: pandamartLogo,
    logoAlt: "giveaway.pandamart.alt",
    title: "giveaway.pandamart.title",
    tracking: "Pandamart - Enjoy $100 off your first $200+ order on pandamart",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=579bb876-edd0-4fca-b9b8-03cfb297d49b",
  },
  {
    logo: mezzainineMakersLogo,
    logoAlt: "giveaway.mezzanine_makers.alt",
    title: "giveaway.mezzanine_makers.title",
    tracking: "Mezzanine Makers 10% off",
    claimUrl:
      "dotted://app?action=claimNFT&tab=home&route=/claimNFTRoute&event_id=e97e01df-511f-4d35-95f4-fa95358fa08d",
  },
];

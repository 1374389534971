import React from "react";

export const MainContext = React.createContext({
  sessionId: "",
  setSessionId: () => {},
  userAgent: "",
  setUserAgent: () => {},
  pathname: "",
  setPathname: () => {},
  language: "en",
  setLanguage: () => {},
  referrerCode: "",
  setReferrerCode: () => {},
  isMobile: false,
  setIsMobile: () => {},
  browserName: "",
  setBrowserName: () => {},
  userIpVersion: "",
  setUserIpVersion: () => {},
  userIp: "",
  setUserIp: () => {},
  userCountryCode: "",
  setUserCountryCode: () => {},
  userCountryName: "",
  setUserCountryName: () => {},
  userCity: "",
  setUserCity: () => {},
  userTimezone: "",
  setUserTimezone: () => {},
  userLatitude: "",
  setUserLatitude: () => {},
  userLongitude: "",
  setUserLongitude: () => {},
  userIpReady: false,
  setUserIpReady: () => {},
  persona_id: "",
  setPersona_id: () => {},
});

// eslint-disable-next-line react/prop-types
export const MainProvider = ({ children }) => {
  const [sessionId, setSessionId] = React.useState("");
  const [userAgent, setUserAgent] = React.useState("");
  const [pathname, setPathname] = React.useState("");
  const [language, setLanguage] = React.useState("tc");
  const [referrerCode, setReferrerCode] = React.useState("");
  const [isMobile, setIsMobile] = React.useState(false);
  const [browserName, setBrowserName] = React.useState("");
  const [userIpVersion, setUserIpVersion] = React.useState("");
  const [userIp, setUserIp] = React.useState("");
  const [userCountryCode, setUserCountryCode] = React.useState("");
  const [userCountryName, setUserCountryName] = React.useState("");
  const [userCity, setUserCity] = React.useState("");
  const [userTimezone, setUserTimezone] = React.useState("");
  const [userLatitude, setUserLatitude] = React.useState("");
  const [userLongitude, setUserLongitude] = React.useState("");
  const [userIpReady, setUserIpReady] = React.useState(false);
  const [persona_id, setPersona_id] = React.useState("");

  return (
    <MainContext.Provider
      value={{
        sessionId,
        setSessionId,
        userAgent,
        setUserAgent,
        pathname,
        setPathname,
        language,
        setLanguage,
        referrerCode,
        setReferrerCode,
        isMobile,
        setIsMobile,
        browserName,
        setBrowserName,
        userIpVersion,
        setUserIpVersion,
        userIp,
        setUserIp,
        userCountryCode,
        setUserCountryCode,
        userCountryName,
        setUserCountryName,
        userCity,
        setUserCity,
        userTimezone,
        setUserTimezone,
        userLatitude,
        setUserLatitude,
        userLongitude,
        setUserLongitude,
        userIpReady,
        setUserIpReady,
        persona_id,
        setPersona_id,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export class Reward {
  constructor(
    merchant,
    image,
    logo,
    briefing,
    title,
    description,
    route,
    deeplink,
    listings,
    listWithHeader
  ) {
    this.merchant = merchant;
    this.image = image;
    this.logo = logo;
    this.briefing = briefing;
    this.title = title;
    this.description = description;
    this.route = route;
    this.deeplink = deeplink;
    this.listings = listings;
    this.listWithHeader = listWithHeader;
  }
}

export class RewardListing {
  constructor(quote, image, description) {
    // prizeType: grand, top, jumbo, general
    this.quote = quote;
    this.image = image;
    this.description = description;
  }
}

export class RewardListWithHeader {
  constructor(title, rewards) {
    this.title = title;
    this.rewards = rewards;
  }
}

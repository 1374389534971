/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from "react";

import { Trans, useTranslation } from "react-i18next";

import colors from "assets/theme/base/colors";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Grid";

// react-router components
import { Link } from "react-router-dom";

import { RegisterContext } from "contexts/RegisterContext";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import OfferList from "pages/MerchantOffers/merchants/OfferList";
import OfferDetailMobile from "../components/OfferDetailMobile";

// eslint-disable-next-line react/prop-types
function OffersMobile() {
  const PRIZE_SPLIT_NUM = 4;
  const { t } = useTranslation();

  const boldStyle = {
    fontWeight: "bold",
  };
  const brStyle = {
    display: "block",
    marginTop: "0em !important",
    marginBottom: "0em !important",
  };

  const titleStyle = {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#0E1E17",
  };

  const descriptionStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: 1.4,
    color: "#737C78",
  };

  // Split OfferList into two parts: Grand Prizes and Normal Prizes
  const [grandPrizeList, setGrandPrizeList] = useState([]);
  const [normalPrizeList, setNormalPrizeList] = useState([]);

  useEffect(() => {
    const upperList = OfferList.slice(0, PRIZE_SPLIT_NUM);
    const lowerList = OfferList.slice(PRIZE_SPLIT_NUM, OfferList.length);
    setGrandPrizeList(upperList);
    setNormalPrizeList(lowerList);
  }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} mt={3} mb={1}>
          <MKTypography align="center" sx={titleStyle}>
            {t("offers.title")}
          </MKTypography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <MKTypography align="center" sx={descriptionStyle}>
            <Trans
              i18nKey="offers.description"
              components={{
                br: <span style={brStyle} />,
              }}
            />
          </MKTypography>
        </Grid>
        <Grid item xs={12} mb="40px">
          <Stack direction="column">
            {grandPrizeList.map((reward, key) => (
              <OfferDetailMobile
                key={`grandPrize${key}`}
                idx={key}
                reward={reward}
                isHighlighted={true}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} mb="24px">
          <MKTypography align="center" sx={titleStyle}>
            {t("offers.morerewards.title")}
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          <MKButton
            variant="gradient"
            sx={{
              mx: "auto",
              color: colors.dttdDark.main,
              display: "relative",
              left: "0%",
              width: "304px",
              height: "48px",
              background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
              borderRadius: "400px",
            }}
          >
            <MKTypography
              component={Link}
              to="/unlock-rewards"
              variant="button"
              fontWeight="bold"
              align="center"
            >
              {t("offers.morerewards.button")}
            </MKTypography>
          </MKButton>
        </Grid>
        <Grid item lg={12} mt="48px" mb="20px">
          <MKTypography align="center" sx={titleStyle}>
            {t("offers.morerewards.description")}
          </MKTypography>
        </Grid>
        <Grid item container xs={12} mb={1}>
          {normalPrizeList.map((reward, key) => (
            <Grid item xs={12} key={`normalPrizeGrid${key}`}>
              <OfferDetailMobile
                key={`normalPrize-${key}`}
                idx={key}
                reward={reward}
                isHighlighted={false}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default OffersMobile;

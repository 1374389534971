import MKTypography from "components/MKTypography";

// react-router components
import { Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types
function RegisterLink({ children }) {
  const registration_url = "https://doubleducks.dttd.io/register";

  return (
    <MKTypography
      component={Link}
      to={registration_url}
      target="_blank"
      rel="noreferrer"
      variant="body3"
      sx={{ fontWeight: 700 }}
    >
      {children}
    </MKTypography>
  );
}

export default RegisterLink;

import { Perk } from ".";

// Perks Images
import regalaSkycity from "assets/images/perks/regala-skycity.png";
import kibo from "assets/images/perks/kibo.png";
import chartCoffee from "assets/images/perks/chart-coffee.png";
//import brickLaneSmall from "assets/images/perks/Brick-Lane.png";
import brickLaneLogo from "assets/images/perks/Brick-Lane.png";
import brickLaneOfferLogo from "assets/images/perks/brick-lane-offer.png";
import campsiteLogo from "assets/images/perks/campsite.png";
import troisLogo from "assets/images/perks/trois.png";
import riceonlyLogo from "assets/images/perks/riceonly.png";
import riceonlyOfferLogo from "assets/images/perks/riceonly-offer.png";
import fayeLogo from "assets/images/perks/faye.png";
import fayeOfferLogo from "assets/images/perks/faye-offer.png";
import nodiLogo from "assets/images/perks/nodi.png";
import nodiOfferLogo from "assets/images/perks/nodi-offer.png";
import maisonKayserLogo from "assets/images/perks/maison-kayser.png";
import maisonKayserOfferLogo from "assets/images/perks/maison-kayser-offer.png";
import labeatLogo from "assets/images/perks/labeat.png";
import liteLogo from "assets/images/perks/lite.png";
import carnabyFairLogo from "assets/images/perks/carnaby-fair.png";
// import mammyPancakeLogo from "assets/images/perks/mammy-pancake.png";
import amalfitanaCentralLogo from "assets/images/perks/Amalfitana-Central.png";
import fratelliLogo from "assets/images/perks/fratelli.png";
import cookieismLogo from "assets/images/perks/cookieism.png";

export const PerkList = [
  new Perk(
    "regal-regala",
    regalaSkycity,
    regalaSkycity,
    "perk.detail.regal-regala-staycation.brief_title",
    "perk.detail.regal-regala-staycation.detail_title",
    "perk.detail.regal-regala-staycation.detail_description",
    "perk.detail.regal-regala-staycation.external_url"
  ),
  new Perk(
    "kibo",
    kibo,
    kibo,
    "perk.detail.kibo.brief_title",
    "perk.detail.kibo.detail_title",
    "perk.detail.kibo.detail_description",
    "perk.detail.kibo.external_url"
  ),
  new Perk(
    "chart-coffee",
    chartCoffee,
    chartCoffee,
    "perk.detail.chart-coffee.brief_title",
    "perk.detail.chart-coffee.detail_title",
    "perk.detail.chart-coffee.detail_description"
  ),
  new Perk(
    "cookieism",
    cookieismLogo,
    cookieismLogo,
    "perk.detail.cookieism.brief_title",
    "perk.detail.cookieism.detail_title",
    "perk.detail.cookieism.detail_description"
  ),
  new Perk(
    "brick-lane",
    brickLaneLogo,
    brickLaneOfferLogo,
    "perk.detail.brick-lane.brief_title",
    "perk.detail.brick-lane.detail_title",
    "perk.detail.brick-lane.detail_description"
  ),
  new Perk(
    "campsite",
    campsiteLogo,
    campsiteLogo,
    "perk.detail.campsite.brief_title",
    "perk.detail.campsite.detail_title",
    "perk.detail.campsite.detail_description"
  ),
  new Perk(
    "trois",
    troisLogo,
    troisLogo,
    "perk.detail.trois.brief_title",
    "perk.detail.trois.detail_title",
    "perk.detail.trois.detail_description"
  ),
  new Perk(
    "riceonly",
    riceonlyLogo,
    riceonlyOfferLogo,
    "perk.detail.riceonly.brief_title",
    "perk.detail.riceonly.detail_title",
    "perk.detail.riceonly.detail_description"
  ),
  new Perk(
    "faye",
    fayeLogo,
    fayeOfferLogo,
    "perk.detail.faye.brief_title",
    "perk.detail.faye.detail_title",
    "perk.detail.faye.detail_description"
  ),
  new Perk(
    "nodi",
    nodiLogo,
    nodiOfferLogo,
    "perk.detail.nodi.brief_title",
    "perk.detail.nodi.detail_title",
    "perk.detail.nodi.detail_description"
  ),
  new Perk(
    "maison-kayser",
    maisonKayserLogo,
    maisonKayserOfferLogo,
    "perk.detail.maison-kayser.brief_title",
    "perk.detail.maison-kayser.detail_title",
    "perk.detail.maison-kayser.detail_description"
  ),
  new Perk(
    "labeat",
    labeatLogo,
    labeatLogo,
    "perk.detail.labeat.brief_title",
    "perk.detail.labeat.detail_title",
    "perk.detail.labeat.detail_description"
  ),
  new Perk(
    "lite",
    liteLogo,
    liteLogo,
    "perk.detail.lite.brief_title",
    "perk.detail.lite.detail_title",
    "perk.detail.lite.detail_description"
  ),
  new Perk(
    "lite",
    liteLogo,
    liteLogo,
    "perk.detail.lite-free-beverage.brief_title",
    "perk.detail.lite-free-beverage.detail_title",
    "perk.detail.lite-free-beverage.detail_description"
  ),
  new Perk(
    "carnaby-fair",
    carnabyFairLogo,
    carnabyFairLogo,
    "perk.detail.carnaby-fair.brief_title",
    "perk.detail.carnaby-fair.detail_title",
    "perk.detail.carnaby-fair.detail_description"
  ),
  // new Perk(
  //   "mammy-pancake",
  //   mammyPancakeLogo,
  //   mammyPancakeLogo,
  //   "perk.detail.mammy-pancake.brief_title",
  //   "perk.detail.mammy-pancake.detail_title",
  //   "perk.detail.mammy-pancake.detail_description",
  //   "",
  //   true
  // ),
  new Perk(
    "amalfitana",
    amalfitanaCentralLogo,
    amalfitanaCentralLogo,
    "perk.detail.amalfitana-central.brief_title",
    "perk.detail.amalfitana-central.detail_title",
    "perk.detail.amalfitana-central.detail_description"
  ),
  new Perk(
    "amalfitana",
    amalfitanaCentralLogo,
    amalfitanaCentralLogo,
    "perk.detail.amalfitana-central-disc.brief_title",
    "perk.detail.amalfitana-central-disc.detail_title",
    "perk.detail.amalfitana-central-disc.detail_description"
  ),
  new Perk(
    "amalfitana",
    amalfitanaCentralLogo,
    amalfitanaCentralLogo,
    "perk.detail.amalfitana-repulse-disc.brief_title",
    "perk.detail.amalfitana-repulse-disc.detail_title",
    "perk.detail.amalfitana-repulse-disc.detail_description"
  ),
  new Perk(
    "fratelli",
    fratelliLogo,
    fratelliLogo,
    "perk.detail.fratelli.brief_title",
    "perk.detail.fratelli.detail_title",
    "perk.detail.fratelli.detail_description"
  ),
];

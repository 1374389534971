export class Perk {
  constructor(
    merchant,
    logoSmall,
    perkImage,
    briefTitle,
    detailTitle,
    detailDescription,
    externalUrl = "",
    soldout = false
  ) {
    this.merchant = merchant;
    this.logoSmall = logoSmall;
    this.perkImage = perkImage;
    this.briefTitle = briefTitle;
    this.detailTitle = detailTitle;
    this.detailDescription = detailDescription;
    this.externalUrl = externalUrl;
    this.soldout = soldout;
  }
}
